import _makeString from "./helper/makeString";
var exports = {};
var makeString = _makeString;

exports = function (str, callback) {
  str = makeString(str);
  if (str.length === 0 || typeof callback !== "function") return str;
  return str.replace(/./g, callback);
};

export default exports;