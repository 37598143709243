import _isBlank from "./isBlank";
import _stripTags from "./stripTags";
import _capitalize from "./capitalize";
import _decapitalize from "./decapitalize";
import _chop from "./chop";
import _trim from "./trim";
import _clean from "./clean";
import _cleanDiacritics from "./cleanDiacritics";
import _count from "./count";
import _chars from "./chars";
import _swapCase from "./swapCase";
import _escapeHTML from "./escapeHTML";
import _unescapeHTML from "./unescapeHTML";
import _splice from "./splice";
import _insert from "./insert";
import _replaceAll from "./replaceAll";
import _include from "./include";
import _join from "./join";
import _lines from "./lines";
import _dedent from "./dedent";
import _reverse from "./reverse";
import _startsWith from "./startsWith";
import _endsWith from "./endsWith";
import _pred from "./pred";
import _succ from "./succ";
import _titleize from "./titleize";
import _camelize from "./camelize";
import _underscored from "./underscored";
import _dasherize from "./dasherize";
import _classify from "./classify";
import _humanize from "./humanize";
import _ltrim from "./ltrim";
import _rtrim from "./rtrim";
import _truncate from "./truncate";
import _prune from "./prune";
import _words from "./words";
import _pad from "./pad";
import _lpad from "./lpad";
import _rpad from "./rpad";
import _lrpad from "./lrpad";
import _sprintf from "./sprintf";
import _vsprintf from "./vsprintf";
import _toNumber from "./toNumber";
import _numberFormat from "./numberFormat";
import _strRight from "./strRight";
import _strRightBack from "./strRightBack";
import _strLeft from "./strLeft";
import _strLeftBack from "./strLeftBack";
import _toSentence from "./toSentence";
import _toSentenceSerial from "./toSentenceSerial";
import _slugify from "./slugify";
import _surround from "./surround";
import _quote from "./quote";
import _unquote from "./unquote";
import _repeat from "./repeat";
import _naturalCmp from "./naturalCmp";
import _levenshtein from "./levenshtein";
import _toBoolean from "./toBoolean";
import _exports from "./exports";
import _escapeRegExp from "./helper/escapeRegExp";
import _wrap from "./wrap";
import _map from "./map";
var exports = {};

function s(value) {
  /* jshint validthis: true */
  if (!(this instanceof s)) return new s(value);
  this._wrapped = value;
}

s.VERSION = "3.3.6";
s.isBlank = _isBlank;
s.stripTags = _stripTags;
s.capitalize = _capitalize;
s.decapitalize = _decapitalize;
s.chop = _chop;
s.trim = _trim;
s.clean = _clean;
s.cleanDiacritics = _cleanDiacritics;
s.count = _count;
s.chars = _chars;
s.swapCase = _swapCase;
s.escapeHTML = _escapeHTML;
s.unescapeHTML = _unescapeHTML;
s.splice = _splice;
s.insert = _insert;
s.replaceAll = _replaceAll;
s.include = _include;
s.join = _join;
s.lines = _lines;
s.dedent = _dedent;
s.reverse = _reverse;
s.startsWith = _startsWith;
s.endsWith = _endsWith;
s.pred = _pred;
s.succ = _succ;
s.titleize = _titleize;
s.camelize = _camelize;
s.underscored = _underscored;
s.dasherize = _dasherize;
s.classify = _classify;
s.humanize = _humanize;
s.ltrim = _ltrim;
s.rtrim = _rtrim;
s.truncate = _truncate;
s.prune = _prune;
s.words = _words;
s.pad = _pad;
s.lpad = _lpad;
s.rpad = _rpad;
s.lrpad = _lrpad;
s.sprintf = _sprintf;
s.vsprintf = _vsprintf;
s.toNumber = _toNumber;
s.numberFormat = _numberFormat;
s.strRight = _strRight;
s.strRightBack = _strRightBack;
s.strLeft = _strLeft;
s.strLeftBack = _strLeftBack;
s.toSentence = _toSentence;
s.toSentenceSerial = _toSentenceSerial;
s.slugify = _slugify;
s.surround = _surround;
s.quote = _quote;
s.unquote = _unquote;
s.repeat = _repeat;
s.naturalCmp = _naturalCmp;
s.levenshtein = _levenshtein;
s.toBoolean = _toBoolean;
s.exports = _exports;
s.escapeRegExp = _escapeRegExp;
s.wrap = _wrap;
s.map = _map; // Aliases

s.strip = s.trim;
s.lstrip = s.ltrim;
s.rstrip = s.rtrim;
s.center = s.lrpad;
s.rjust = s.lpad;
s.ljust = s.rpad;
s.contains = s.include;
s.q = s.quote;
s.toBool = s.toBoolean;
s.camelcase = s.camelize;
s.mapChars = s.map; // Implement chaining

s.prototype = {
  value: function value() {
    return this._wrapped;
  }
};

function fn2method(key, fn) {
  if (typeof fn !== "function") return;

  s.prototype[key] = function () {
    var args = [this._wrapped].concat(Array.prototype.slice.call(arguments));
    var res = fn.apply(null, args); // if the result is non-string stop the chain and return the value

    return typeof res === "string" ? new s(res) : res;
  };
} // Copy functions to instance methods for chaining


for (var key in s) fn2method(key, s[key]);

fn2method("tap", function tap(string, fn) {
  return fn(string);
});

function prototype2method(methodName) {
  fn2method(methodName, function (context) {
    var args = Array.prototype.slice.call(arguments, 1);
    return String.prototype[methodName].apply(context, args);
  });
}

var prototypeMethods = ["toUpperCase", "toLowerCase", "split", "replace", "slice", "substring", "substr", "concat"];

for (var method in prototypeMethods) prototype2method(prototypeMethods[method]);

exports = s;
export default exports;